import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`👷🏾👷 Roles`}</h1>
    <p>{`Many of these roles can be performed by the same person, and/or within one organization.`}</p>
    <h3>{`The roles`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{``}<a parentName="strong" {...{
              "href": "/owner",
              "title": "owner"
            }}>{`owner`}</a>{``}</strong></p>
        <p parentName="li">{`— who will be responsible for `}<a parentName="p" {...{
            "href": "/Usage",
            "title": "🔩 Usage"
          }}>{`🔩 Usage`}</a>{` & `}<a parentName="p" {...{
            "href": "/Analysis",
            "title": "🔬 Analysis"
          }}>{`🔬 Analysis`}</a>{`?`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`🙋‍♀️🙋‍♂️`}<a parentName="strong" {...{
              "href": "/facilitation",
              "title": "facilitation"
            }}>{`facilitation`}</a>{` practitioner`}</strong></p>
        <p parentName="li">{`— who will craft the initial question, design seed statements, and make sure everyone understands the process?`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`👀eyeball provider`}</strong></p>
        <p parentName="li">{`— how will participants know there is a process?`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`👩‍💻👨‍💻tech integrator`}</strong></p>
        <p parentName="li">{`— who will integrate pol.is' embed code on a website?`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`🏛legitimacy provider`}</strong></p>
        <p parentName="li">{`— why should `}<a parentName="p" {...{
            "href": "/participants",
            "title": "participants"
          }}>{`participants`}</a>{` trust the integrity of this process?`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`🏟`}<a parentName="strong" {...{
              "href": "/participants",
              "title": "participants"
            }}>{`participants`}</a>{``}</strong></p>
        <p parentName="li">{`— who will `}<a parentName="p" {...{
            "href": "/vote",
            "title": "vote"
          }}>{`vote`}</a>{`?`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`👩‍⚖️👨‍⚖️`}<a parentName="strong" {...{
              "href": "/moderator",
              "title": "moderator"
            }}>{`moderator`}</a>{``}</strong></p>
        <p parentName="li">{`— who will make sure added comments are safe & high quality?`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`👩‍🏫👨‍🏫data scientist`}</strong></p>
        <p parentName="li">{`— who will do the post conversation analysis and share-back? May involve conversations with data scientists about `}<a parentName="p" {...{
            "href": "/data-science-methods",
            "title": "data science methods"
          }}>{`data science methods`}</a>{``}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`💪🔌🏰connection to power`}</strong></p>
        <p parentName="li">{`— how does `}<a parentName="p" {...{
            "href": "/participation",
            "title": "participation"
          }}>{`participation`}</a>{` become change?`}</p>
      </li>
    </ol>
    <h3>{`E.g., 📰🗞a newspaper`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{``}<a parentName="strong" {...{
              "href": "/owner",
              "title": "owner"
            }}>{`owner`}</a>{``}</strong></p>
        <p parentName="li">{`— news product or a department of the newsroom`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`🙋‍♀️🙋‍♂️`}<a parentName="strong" {...{
              "href": "/facilitation",
              "title": "facilitation"
            }}>{`facilitation`}</a>{` practitioner`}</strong></p>
        <p parentName="li">{`— journalist or external partner firm`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`👀eyeball provider`}</strong></p>
        <p parentName="li">{`— the news organization's digital site, a single article about an issue`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`👩‍💻👨‍💻tech integrator`}</strong></p>
        <p parentName="li">{`— newspaper web team`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`🏛legitimacy provider`}</strong></p>
        <p parentName="li">{`— the newspaper itself, an academic partner`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`🏟`}<a parentName="strong" {...{
              "href": "/participants",
              "title": "participants"
            }}>{`participants`}</a>{``}</strong></p>
        <p parentName="li">{`— the readers of the newspaper`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`👩‍⚖️👨‍⚖️`}<a parentName="strong" {...{
              "href": "/moderator",
              "title": "moderator"
            }}>{`moderator`}</a>{``}</strong></p>
        <p parentName="li">{`— journalist or editor`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`👩‍🏫👨‍🏫data scientist`}</strong></p>
        <p parentName="li">{`— non programming, non data scientist journalists using the automated `}<a parentName="p" {...{
            "href": "/report",
            "title": "report"
          }}>{`report`}</a>{`, or a data journalist pulling the raw data and doing follow on `}<a parentName="p" {...{
            "href": "/Analysis",
            "title": "🔬 Analysis"
          }}>{`🔬 Analysis`}</a>{``}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`💪🔌🏰connection to power`}</strong></p>
        <p parentName="li">{`— `}<a parentName="p" {...{
            "href": "((6WuBVkPuC))"
          }}>{`town hall event sponsored by the paper, reporting`}</a>{`, or `}<a parentName="p" {...{
            "href": "((Gs6KJcVYA))"
          }}>{`ministerial partner`}</a></p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      